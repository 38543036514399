<template>
  <div class="login-page flex justify-content-center align-items-center">
    <div
      class="card-pf flex flex-column justify-content-evenly align-items-center mb-5 p-5">
      <div class="d-block pt-5">
        <a href="https://paraiba.pb.gov.br/" role="link" target="_Blank">
          <img
            class="card-desktop"
            src="@/img/logo-sead-gov.png"
            alt="Governo do Estado da Paraíba"
            width="270" />
          <img
            class="card-mobile"
            src="@/img/logo-sead-gov.png"
            alt="Governo do Estado da Paraíba"
            width="250" />
        </a>
      </div>
      <div class="col-12">
        <h2 style="color: #00a3e4" class="text-center">
          Escolha como deseja acessar <br class="card-desktop" />
          o Portal do Servidor
        </h2>
      </div>
      <div class="col-12">
        <div class="flex flex-wrap justify-content-around">
          <Button
            class="col-12 md:col-5 button-portal-velho mb-2 md:mb-0"
            type="submit"
            @click="abrirPortalAntigo">
            <span class="p-button-label">Portal do Servidor Antigo</span>
          </Button>
          <Button
            type="submit"
            class="col-12 md:col-5 button-portal-novo"
            @click="abrirNovoPortal">
            <span class="p-button-label">Novo Portal do Servidor</span>
          </Button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import UseVuelidate from '@vuelidate/core'
import { required } from '@vuelidate/validators'
import Config from '@/config'

export default {
  name: 'LoginIndex',

  props: {
    titulo: {
      type: String,
      default: '',
    },
  },

  setup() {
    return { v$: UseVuelidate() }
  },

  data() {
    return {
      username: '',
      password: '',
      submitted: false,
      http_login: null,
      loading: false,
    }
  },

  computed: {
    recuperarEmail: () => {
      return `${Config.PRIMEIRO_ACESSO_URL}`
    },

    resetLink: () => {
      return `${Config.REALM_PB}/login-actions/reset-credentials?client_id=${Config.CLIENT_ID}`
    },
  },

  created() {
    this.http_login = axios.create({
      baseURL: Config.REALM_PB,
    })
  },

  validations() {
    return {
      username: { required },
      password: { required },
    }
  },

  methods: {
    abrirPortalAntigo() {
      window.open('https://sead.secadm.pb.gov.br/portaldoservidor/', '_self')
    },
    abrirNovoPortal() {
      this.$router.push({ name: 'login' })
    },
    validate(isFormValid) {
      this.submitted = true
      if (!isFormValid) {
        this.$toast.add({
          severity: 'warn',
          summary: 'Usuário ou senha inválido(s).',
          life: 10000,
        })
        return
      }
      this.submit()
    },

    irParaVersaoAntiga() {
      location.href = 'https://sead.secadm.pb.gov.br/portaldoservidor/'
    },

    async parseJwt(token) {
      var base64Url = token.split('.')[1]
      var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
      var jsonPayload = decodeURIComponent(
        atob(base64)
          .split('')
          .map(function (c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
          })
          .join(''),
      )
      return JSON.parse(jsonPayload)
    },

    async submit() {
      this.loading = true
      var qs = require('qs')
      var user = qs.stringify({
        client_id: Config.CLIENT_ID,
        username: this.username.replace(/\D/g, ''),
        password: this.password,
        grant_type: 'password',
      })

      try {
        const { data } = await this.http_login.post(
          '/protocol/openid-connect/token',
          user,
        )
        var jsonparsed = await this.parseJwt(data.access_token)
        if (!jsonparsed.resource_access.pbconsig) {
          throw {
            response: {
              data: { error_description: 'Usuário sem permissões.' },
            },
          }
        }
        this.success(
          data,
          jsonparsed.resource_access.pbconsig.roles,
          jsonparsed,
        )
        this.loading = false
      } catch (error) {
        this.loading = false
        this.$toast.add({
          severity: 'error',
          summary: this.getErrorMessage(error),
          life: 10000,
        })
      }
    },

    async success(data, roles, jsonparsed) {
      const usuario = {
        email: jsonparsed.email,
        primeironome: jsonparsed.given_name,
        sobrenome: jsonparsed.family_name,
        nomecompleto: jsonparsed.name,
        usuario: jsonparsed.preferred_username,
        uuid: jsonparsed.sub,
      }

      await this.$auth.login({
        user: usuario,
        token: data.access_token,
        refresh_token: data.refresh_token,
        roles: roles,
        expires_in: data.expires_in,
      })

      this.$router.push('/').catch(() => {
        this.$toast.add({
          severity: 'error',
          summary: 'Acesso não autorizado a este módulo.',
          life: 10000,
        })
      })
    },

    getErrorMessage(error) {
      if (error.response) {
        switch (error.response.data.error_description) {
          case 'Account is not fully set up':
            return 'Usuário com restrição. Entre em contato com a gestão do PBConsig.'
          case 'Invalid user credentials':
            return 'Usuário ou senha inválido(s)'
          case 'Account disabled':
            return 'Conta desativada.'
          default:
            return error.response.data.error_description
        }
      }
      return 'Erro ao realizar login. Verifique sua conexão.'
    },
  },
}
</script>

<style scoped>
.button-portal-velho {
  background-color: grey;
  border-color: grey;
}
.button-portal-novo {
  background-color: #00a3e4;
  border-color: #00a3e4;
}

.login-page {
  font-family: 'Roboto', 'Open Sans', Helvetica, Arial, sans-serif;

  background-size: cover;
  background-repeat: repeat;
  background-color: #1c84f7 !important;
  height: 100vh;
}

.logos {
  max-width: 500px;
  height: 50px;
  display: flex;
}

.logo_codata {
  margin: 10px auto 0;
}

.logo_codata a img {
  max-width: 15rem;
  display: flex;
}

.logo_governo {
  margin: auto;
}

.card-pf {
  padding-top: 0 !important;
  border-radius: 16px 16px 16px 16px;
  max-width: 700px !important;
  width: 700px !important;
  height: 400px;
  background-color: white;
}

#kc-social-providers {
  margin-top: 25px;
}

#kc-social-providers ul {
  align-items: center;
  display: grid !important;
}

.login-pf-signup {
  margin-top: 5px !important;
}

@media (min-width: 768px) and (max-width: 900px) {
  .login-pf-page .login-pf-page-header {
    margin-bottom: 20px !important;
  }
}

@media (min-width: 410px) and (max-width: 767px) {
  .login-pf-page .login-pf-page-header {
    margin-bottom: 0px !important;
    height: 10px !important;
  }
}

@media (max-width: 410px) {
  .login-pf-page .login-pf-page-header {
    margin-bottom: 0px !important;
    height: 5px !important;
  }
}

.info-servicos {
  margin-top: 30px;
  margin-bottom: 40px;
  font-size: 18px;
  color: var(--blue-color) !important;
  font-weight: 500;
  line-height: 27px;
  text-align: center;
}

.info-clique-botao,
.info-govbr {
  text-align: center;
  font-size: 14px;
  color: #777;
  line-height: 24px;
  font-weight: 500;
}

.govBr {
  color: #1351b4;
  font-weight: 700;
}

.separador {
  height: 1px;
  background: #d0948a;
  margin-bottom: 10px !important;
}

#kc-info-wrapper p {
  font-size: 12px;
  font-weight: 500;
  color: #777;
  margin: 0 0 8px;
}

#kc-info-wrapper p a {
  margin-left: 0px !important;
}

.login-pf-page,
#kc-info {
  padding-bottom: 20px;
}

#kc-content {
  height: 400px;
}

.grid_cidadao {
  margin: 0 auto;
  width: 980px;
  display: block;
}

.form_cidadao {
  display: grid;
  grid-template-columns: 420px 560px;
}

.cidadao_conectado {
  background-color: #145da1;
  /* background-image: url("./ilustracao.png") !important; */

  height: 580px;
  width: 420px;
  border-radius: 16px 0 0 16px;
}

:root {
  --shadow: 0 0px 3px -2px rgba(0, 0, 0, 0.2), 0 0px 4px 0 rgba(0, 0, 0, 0.14),
    0 0px 8px 0 rgba(0, 0, 0, 0.12);
}

.cidadao_conectado,
.card-pf {
  box-shadow: var(--shadow);
}

@media (max-width: 840px) {
  .login-pf-page .card-pf {
    margin-left: 0px !important;
  }
}

@media (max-width: 1000px) {
  .cidadao_conectado {
    display: none;
  }

  .card-pf {
    border-radius: 16px 16px 16px 16px;
  }
}

@media (min-width: 700px) and (max-width: 1000px) {
  .grid_cidadao {
    width: 560px;
  }
}

@media (min-width: 500px) and (max-width: 700px) {
  .grid_cidadao {
    width: 470px;
  }

  .form_cidadao {
    display: block;
  }

  .card-pf {
    max-width: 470px !important;
    width: 100%;
  }

  #kc-content {
    height: 420px;
  }
}

@media (max-width: 499px) {
  .grid_cidadao {
    width: 350px;
  }

  .form_cidadao {
    display: block;
  }

  .card-pf {
    max-width: 350px !important;
  }

  #kc-content {
    height: 500px;
  }
}

@media (max-height: 810px) {
  #kc-header {
    display: none;
  }
}
</style>
